<template>
  <div>
    <Breadcrumb activePage="課程" />
    <div class="courses">
      <!-- <div class="d-flex justify-content-between align-items-center px-4">
        <input
          type="text"
          name="query"
          class="form-control mb-1 courses__query"
          id="query"
          v-model="query"
          placeholder="搜尋課程..."
        >
        <select class="form-select courses__filter ms-auto mb-3" aria-label="courses__filter" v-model="filter">
          <option selected value="date">日期</option>
          <option value="active">進行中課程</option>
          <option value="finished">已完成課程</option>
        </select>
      </div> -->
      <div class="row mt-4">
        <div class="col-xl-4 mb-4 mb-md-0">
          <div class="card">
            <div class="card-body profile-card pt-4 d-flex flex-column align-items-center profile__card">
              <img :src="Avatar" alt="Profile" class="rounded-circle">
              <h2 class="mt-2 profile__name">{{ user.userName }}</h2>
              <h3 class="mt-2 profile__identity">{{ user.status }}</h3>
              <web3_connect
                :API_URL="API_URL"
                :extra_sign_data="extra_sign_data"
                @update="updateWallet"
                @toast="toast"
                @getUser="getUser"
                :walletAddress="user.walletAddress"
                :isValidityOwner="user.isValidityOwner"
              ></web3_connect>
            </div>
          </div>
        </div>
        <div class="col-xl-8">
          <div class="d-flex justify-content-between align-items-center mb-3" v-if="role == 'Admin' || role == 'Teacher'">
            <h3 class="courses__tabTitle fw-bold text-start">我開的課</h3>
            <button class="btn btn-main" @click.prevent="createCourse">我要開課</button>
          </div>
          <div class="container-fluid" v-if="role == 'Admin' || role == 'Teacher'">
            <div class="row" v-if="!noData">
              <div class="col-12 col-sm-6 col-lg-4 mb-4" v-for="item in computedData" :key="item.id">
                <CourseCardAuth :course="item">
                  <template slot="edit">
                    <span class="btn btn-edit" @click.stop="$router.push(`/dashboard/edit_course/ebasic/${item.id}`)">
                      <i class="fas fa-edit"></i>
                    </span>
                  </template>
                </CourseCardAuth>
              </div>
              <div class="course__review__pages mt-3 d-flex justify-content-center">
                <i class="fas fa-chevron-left me-3 course__review__page--switch"
                  v-if="currentPagenum > 1" @click.prevent="switchPage(-1)"></i>
                <i class="fas fa-chevron-right ms-3 course__review__page--switch"
                  v-if="currentPagenum < ownedPageSum" @click.prevent="switchPage(1)"></i>
              </div>
            </div>
            <div v-if="noData">
              <p class="noData__txt">尚無課程</p>
              <img src="../assets/images/empty.svg" alt="" width="250">
            </div>
          </div>

          <h3 class="courses__tabTitle fw-bold text-start mb-3" v-if="role == 'Member'">我修的課</h3>
          <div class="container-fluid" v-if="role == 'Member'">
            <div class="row" v-if="!noData">
              <div class="col-12 col-sm-6 col-lg-4 mb-4" v-for="item in purchasedCourses" :key="item.id">
                <CourseCardAuth :course="item" />
              </div>
            </div>
            <div v-if="noData">
              <p class="noData__txt">尚無課程</p>
              <img src="../assets/images/empty.svg" alt="" width="250">
            </div>
          </div>

        </div>
      </div>
      <!-- <nav class='my-5'>
        <ul class="pagination justify-content-center">
          <li class="page-item">
            <a href="#" class="page-link" @click.prevent="">上一頁</a>
          </li>
          <li class="page-item">
            <a href="#" class="page-link" @click.prevent="">1</a>
          </li>
          <li class="page-item">
            <a href="#" class="page-link" @click.prevent="">下一頁</a>
          </li>
        </ul>
      </nav> -->
    </div>
    <Meta :title ="title" />
  </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Breadcrumb from '../components/Breadcrumb';
import CourseCardAuth from '../components/CourseCardAuth';
import web3_connect from '../components/web3_connect';

const Swal = require('sweetalert2');

export default {
  name: 'Courses',
  components: {
    Breadcrumb,
    web3_connect,
    CourseCardAuth
  },
  data() {
    return {
      query: '',
      data: [],
      purchasedCourses: [],
      ownedCourses: [],
      ownedPageSum: 0,
      currentPagenum: 1,
      purchasedPageSum: 0,
      purchasedcurrentPagenum: 1,
      filter: 'date',
      user: {
        userName: 'Kevin Anderson',
        status: '教師',
        email: 'k.anderson@example.com',
        link: 'https://twitter.com/#',
        password: '123456',
        getNotice: true,
        walletAddress: '',
        isValidityOwner: false,
      },
      noData: false,
      API_URL: 'https://api1.metalearning.com.tw/Member/Web3',
      extra_sign_data: '測試額外資料',
      wallet: {
        web3: null,
        account: null,
        provider: null,
        response: null,
      },
      title: '您的課程 - 元學堂',
    };
  },
  metaInfo: {
    title: "您的課程 - 元學堂",
    meta: [
      { name: 'description', content: '您所開的課程及所修的課程列表。' },
      {
        property: 'og:title',
        content: '您的課程 - 元學堂',
      },
      {
        property: 'og:description',
        content: '您所開的課程及所修的課程列表。',
      },
    ]
  },
  methods: {
    getData() {
      const query = {
        CourseName: 'SOLIDWORKS',
        CourseCategoryId: 1,
        OrderBy: 'lastest',
        Ordering: 'ASC',
        page: 1,
      };
      this.updateLoading(true);
      this.$http.get(`${this.$API_PATH}/Course/Selected?Take=6`, {
        headers: {
          Accept: '*/*',
        },
      }).then((res) => {
        this.$log.debug(res.data.data.selectedCourseList);
        this.purchasedCourses = res.data.data.selectedCourseList.slice(0, 5);
        this.ownedCourses = res.data.data.selectedCourseList.slice(0, 5);
        this.updateLoading(false);
        if (res.data.data.courseList.length == 0) {
          this.noData = true;
        } else {
          this.noData = false;
        }
      }).catch((err) => {
        this.updateLoading(false);
        if (err.response) {
          if (err.response.status == 404 || err.response.status == 400) {
            Swal.fire(
              '',
              `${err.response.data.message}`,
              'info',
            ).then((result) => {
              if (result.isConfirmed) {
                this.$router.push('/');
              }
            });
          }
        } else {
          Swal.fire(
            '錯誤',
            '請稍候再試',
            'info',
          ).then((result) => {
            if (result.isConfirmed) {
              this.$router.push('/');
            }
          });
        }
      });
    },
    getUser() {
      const vm = this;
      this.updateLoading(true);
      vm.$http.get(`${this.$API_PATH}/Member/Profile`, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${vm.userAuth}`,
        },
      }).then((res) => {
        vm.user.userName = res.data.data.userName;
        vm.user.email = res.data.data.email;
        vm.user.link = res.data.data.relatedLink;
        vm.user.status = res.data.data.roles[0];
        vm.user.walletAddress = res.data.data.web3.walletAddress;
        vm.user.isValidityOwner = res.data.data.web3.isValidityOwner;
        this.updateLoading(false);
      }).catch((err) => {
        // this.$router.push('/error');
        if (err.response) {
          if (err.response.status == 404 || err.response.status == 400) {
            Swal.fire(
              '',
              `${err.response.data.message}`,
              'info',
            ).then((result) => {
              if (result.isConfirmed) {
                this.$router.push('/');
              }
            });
          }
        } else {
          Swal.fire(
            '錯誤',
            '請稍候再試',
            'info',
          ).then((result) => {
            if (result.isConfirmed) {
              this.$router.push('/');
            }
          });
        }
      });
    },
    createCourse() {
      this.$store.dispatch('courseModule/resetCourseData');
      localStorage.removeItem('syllabus');
      this.$store.dispatch('syllabusModule/getSyllData', [{
        id: Math.floor(Date.now()),
        title: '',
        section: [{
          id: Math.floor(Date.now() + 10),
          title: '',
          videoUrl: '',
          videoId: '',
          desc: '',
          lock: true,
          time: '',
        }],
      }]);
      this.$store.dispatch('courseModule/resetCourseData');
      this.$router.push('/dashboard/edit_course/basic');
    },
    updateWallet(val) {
      this.wallet = val;
    },
    toast() {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: 'success',
        title: '複製完成',
      });
    },
    getOwnCourse() {
      const vm = this;
      this.updateLoading(true);
      vm.$http.get(`${this.$API_PATH}/Member/CreatedCourse`, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${vm.userAuth}`,
        },
      }).then((res) => {
       // console.log(res.data);
        if (res.data.data.courseList.length == 0) {
          this.noData = true;
        } else {
          this.noData = false;
        }
        this.ownedCourses = [...res.data.data.courseList];
        // console.log(this.ownedCourses);
        this.updateLoading(false);
      }).catch((err) => {
        // this.$router.push('/error');
        this.updateLoading(false);
        if (err.response) {
          if (err.response.status == 404 || err.response.status == 400) {
            Swal.fire(
              '',
              `${err.response.data.message}`,
              'info',
            ).then((result) => {
              if (result.isConfirmed) {
                this.$router.push('/');
              }
            });
          }
        } else {
          Swal.fire(
            '錯誤',
            '請稍候再試',
            'info',
          ).then((result) => {
            if (result.isConfirmed) {
              this.$router.push('/');
            }
          });
        }
      });
    },
    getPurchasedCourse() {
      const vm = this;
      this.updateLoading(true);
      vm.$http.get(`${this.$API_PATH}/Member/MyCourse`, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${vm.userAuth}`,
        },
      }).then((res) => {
        // console.log(res.data);
        if (res.data.data.courseList.length == 0) {
          this.noData = true;
        } else {
          this.noData = false;
        }
        this.purchasedCourses = [...res.data.data.courseList];
        // console.log(this.purchasedCourses);
        this.updateLoading(false);
      }).catch((err) => {
        // this.$router.push('/error');
        this.updateLoading(false);
        if (err.response) {
          if (err.response.status == 404 || err.response.status == 400) {
            Swal.fire(
              '',
              `${err.response.data.message}`,
              'info',
            ).then((result) => {
              if (result.isConfirmed) {
                this.$router.push('/');
              }
            });
          }
        } else {
          Swal.fire(
            '錯誤',
            '請稍候再試',
            'info',
          ).then((result) => {
            if (result.isConfirmed) {
              this.$router.push('/');
            }
          });
        }
      });
    },
    setShows(datas) {
      const pages = Math.ceil(datas.length / 6);
      // console.log(pages);
      this.ownedPageSum = pages;
      const startNum = (parseInt(this.currentPagenum, 10) - 1) * 6;
      const endNum = ((parseInt(this.currentPagenum, 10) - 1) * 6 + 6);
      // console.log(startNum, endNum);
      return datas.slice(startNum, endNum);
    },
    switchPage(num) {
      this.currentPagenum += num;
      if (this.currentPagenum <= 1) {
        this.currentPagenum = 1;
      }
      if (this.currentPagenum > this.ownedPageSum) {
        this.currentPagenum = this.ownedPageSum;
      }
    },
    ...mapActions(['updateLoading', 'getUserAuth', 'getUserAuthFromLocal', 'getUserFromLocal', 'checkExpAuth', 'updateLoading']),
  },
  computed: {
    computedData() {
      return this.setShows(this.ownedCourses);
    },
    ...mapGetters(['userName', 'Avatar', 'userId', 'role', 'exp', 'userAuth', 'LoginProvider']),
  },
  created() {
    this.getUserAuthFromLocal();
    this.getUserFromLocal();
    this.checkExpAuth();
    if (this.userAuth.length == 0) {
      this.$router.push('/login');
    }
    // console.log(this.role);
    if (this.role == 'Admin' || this.role == 'Teacher') {
      this.getOwnCourse();
    } else if (this.role == 'Member') {
      this.getPurchasedCourse();
    }
    this.getUser();
    // this.getData();
  },
};
</script>

<style lang="scss" scoped>
  .btn-edit {
    position: absolute;
    right: .8rem;
    top: .8rem;
    padding: .48rem .6rem;
    background-color: white;
    border-radius: 50%;
    z-index: 10;
    box-shadow: rgba(0, 0, 0, 0.13) 0px 2px 2px 0px;
  }
</style>
